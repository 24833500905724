<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车场管理</el-breadcrumb-item>
      <el-breadcrumb-item>固定车辆查询</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pk_id"
            filterable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >车主姓名：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入车主姓名"
            v-model="query.trueName"
            clearable
          />
        </label>
        <label style="margin: 10px" for=""
          >车主手机号：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入手机号"
            v-model="query.mobile"
            clearable
          />
        </label>
        <label style="margin: 10px" for=""
          >车牌号：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入车牌号"
            v-model="query.plateNo"
            clearable
          />
        </label>
        <label style="margin: 10px" for=""
          >创建时间：<el-date-picker
            v-model="endTimeBegin"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="endTimeEnd"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
            @change="inTimeEndchange"
          >
          </el-date-picker
        ></label>
        <label style="margin: 10px" for=""
          >收费类型：
          <el-select
            v-model="query.kindId"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in chargeType"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="userInfoKindListcx"
          >查询</el-button
        >
        <el-button size="small" @click="userInfoKindListcz()">重置</el-button>
      </div>
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="trueName"
            label="车主姓名"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="mobile"
            label="车主手机"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="kindName"
            label="收费类型名称"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="beginTime"
            label="开始时间"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.beginTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="endTime"
            label="到期时间"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.endTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="120"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="isShowQueryDialog(scope)"
                size="mini"
                icon="el-icon-share"
                >查看用户车辆</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 查看用户车辆 -->
    <el-dialog
      :title="userName + '的车辆'"
      :visible.sync="isShowQuery"
      width="40%"
      v-el-drag-dialog
    >
      <el-table :data="tableDatatk" style="width: 100%">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="plateNo" label="车牌号"></el-table-column>
        <el-table-column prop="carType" label="车型">
          <template slot-scope="scope">
            <span v-if="scope.row.carType">{{
              findtranslate(typeCar, scope.row.carType)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="carColor" label="车牌颜色">
          <template slot-scope="scope">
            <span v-if="scope.row.carColor">{{
              findtranslate(typeColor, scope.row.carColor)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="carBrand" label="车品牌"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  pkInCarsList,
  findPkInfoSelect,
  delPkInCars,
  userInfoKindList,
  findUserCar,
} from '@/api/Parkinglot.js'
import { sysDictData } from '@/api/dictionaries.js'
export default {
  data() {
    return {
      loading: false,
      isShowData: false,
      isShowQuery: false,
      tableData: [],
      tableDatatk: [],
      endTimeBegin: undefined, // 到期时间起始
      endTimeEnd: undefined, // 到期时间截至
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pk_id: '', // 停车场id
        trueName: undefined, // 车主姓名
        mobile: undefined, // 车主手机号
        plateNo: undefined,
        endTimeBegin: undefined, // 到期时间起始
        endTimeEnd: undefined, // 到期时间截至
        kindId: undefined, // 收费类型id
      },
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
      userName: undefined, // 保存当前用户名字
      typeCar: [],
      typeColor: [],
      chargeType: [],
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  methods: {
    userInfoKindList() {
      // 进出记录查询列表
      this.query.endTimeBegin = this.endTimeBegin
      this.query.endTimeEnd = this.endTimeEnd
      if (this.query.endTimeBegin && !this.query.endTimeEnd) {
        return this.$message.warning('请输入截止时间')
      } else if (!this.query.endTimeBegin && this.query.endTimeEnd) {
        return this.$message.warning('请输入开始时间')
      }
      if (this.query.endTimeBegin || this.query.endTimeEnd) {
        this.query.endTimeBegin = (this.query.endTimeBegin / 1000).toFixed(0)
        this.query.endTimeEnd = (this.query.endTimeEnd / 1000).toFixed(0)
      }
      userInfoKindList(this.query, { pk_id: this.query.pk_id }).then((res) => {
        this.tableData = res.data.data.list
        this.total = res.data.data.total
        if (this.tableData) {
          this.isShowData = true
          this.loading = false
        }
      })
    },
    userInfoKindListcx() {
      // 查询
      this.query.current = 1
      this.userInfoKindList()
    },
    userInfoKindListcz() {
      // 重置
      this.query.pk_id = this.PkInfoSelect[0].id
      this.query.current = 1
      this.query.size = 10
      this.query.trueName = undefined
      this.query.mobile = undefined
      this.query.plateNo = undefined
      this.endTimeBegin = undefined
      this.endTimeEnd = undefined
      this.query.kindId = undefined
      this.userInfoKindList()
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.query.pk_id = this.PkInfoSelect[0].id
          this.userInfoKindList()
          this.sysDictData()
        }
      })
    },
    isShowQueryDialog(scope) {
      // 显示查询用户车
      const mobile = {
        mobile: scope.row.mobile,
      }
      this.userName = scope.row.trueName
      findUserCar(mobile).then((res) => {
        this.tableDatatk = res.data.data
        this.isShowQuery = true
      })
    },
    handleSizeChange(val) {
      this.loading = true
      this.query.size = val
      this.userInfoKindList()
    },
    handleCurrentChange(val) {
      this.loading = true
      this.query.current = val
      this.userInfoKindList()
    },
    sysDictData() {
      // 字典
      sysDictData({ typeId: 40 }).then((res) => {
        // 车类型
        this.typeCar = res.data.data
      })
      sysDictData({ typeId: 80 }).then((res) => {
        // 车类型
        this.chargeType = res.data.data
      })
      sysDictData({ typeId: 81 }).then((res) => {
        // 车颜色
        this.typeColor = res.data.data
      })
    },
    findtranslate(data, id) {
      // 翻译字典
      return data.find((item) => item.code === id).name
    },
    // 结束时间选择
    inTimeEndchange() {
      if (this.endTimeBegin === null || this.endTimeBegin === undefined) {
        this.endTimeEnd = null
        return this.$message.warning('请选择开始时间')
      } else if (this.endTimeEnd < this.endTimeBegin) {
        this.endTimeEnd = this.endTimeBegin + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
